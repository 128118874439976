@font-face {
  font-family: "Sk-Modernist-Bold";
  src: url("fonts/Sk-Modernist-Bold.woff") format("woff"), url("fonts/Sk-Modernist-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Sk-Modernist-Mono";
  src: url("fonts/Sk-Modernist-Mono.woff") format("woff"), url("fonts/Sk-Modernist-Mono.otf") format("opentype");
}

@font-face {
  font-family: "Sk-Modernist-Regular";
  src: url("fonts/Sk-Modernist-Regular.woff") format("woff"), url("fonts/Sk-Modernist-Regular.otf") format("opentype");
}

#root {
  height: 100vh;
}

html, body {
  height: 100%;
  font-family: "Sk-Modernist-Regular";
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #000;
  line-height: 1.2;
  overflow: initial;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6
{
    font-family: "Sk-Modernist-Regular";
}

.ant-modal-mask, .ant-image-preview-mask
{background-color: rgba(44, 56, 76, 0.92);}

.ant-modal-wrap, .ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: scroll;
  outline: 0;
}

.body::-webkit-scrollbar{
  width: 0px;
}

.form-item {
  margin-top: 30px;
  align-items: center;
  justify-content: center;
}

.form-item-noborder {
  border: none !important;
  padding: 0px;
}

.submit-button {
  margin: auto;
  width: auto;
  display: inline-block;
  .ant-form-item-control-input-content {
    border: none;
  }
}

.page-title {
  font-family: "Sk-Modernist-Bold";
  font-style: normal;
  font-size: 32px;
  line-height: 42px;
  color: #2C384C;
}

.signup-link-div {
    text-align: center;
    margin-top: 20px;
}
.fixed-button-bottom {
  margin-top: 30px;
  text-align: center;
  width: 100%;
  left: 0;
  bottom: 15px;
}
.signup-link-label {
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
  color: #7B7B7B;
  font-family: "Sk-Modernist-Bold";
}

.signup-link-button {
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #2C384C;
  font-family: "Sk-Modernist-Bold";
}

.ant-form-item-explain {
  color: red;
  font-size: 12px;
  padding-left: 10px;
  padding-top: 6px;
}

.ant-form-item-label {
  font-size: 12px;
  padding-top: 0px!important;
}

.ant-form-horizontal .ant-form-default {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.ant-input-affix-wrapper {
  background: #FFFFFF;
  border: 1px solid #929A9A;
  box-sizing: border-box;
  border-radius: 12px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  font-family: "Sk-Modernist-Bold";
  color: #7B7B7B;
  padding: 12px 10px;
}

.inputicon{
  .ant-input-prefix {
    position: relative;
    top: 0px;
    width: 32px;
    display: inline-block;
  }
  .ant-input-prefix::after {
    position: absolute;
    content: "";
    height: 40px;
    width: 1px;
    background-color: #C4C4C4;
    right: 0px;
    top: -8px;
  }
  .ant-input {
    border: none;
    outline: none;
    font-style: normal;
    font-family: "Sk-Modernist-Regular";
    font-size: 17px;
    line-height: 20px;
    color: #7B7B7B;
    width: calc(100% - 32px);
  }
  .ant-form-item-control-input-content { position: relative;}
  .profile_info .ant-input{width: calc(100% - 90px);}
}

.ant-picker-input > input::placeholder{
  color: #7B7B7B !important;
  opacity: 1;
}

.order-modal{
  border-radius: 12px !important;
  .ant-select{
    border: none;
    width: -webkit-fill-available;
    padding: 10px 0;
  }
  .ant-modal-header{
    border-radius: 12px !important;
    border: none;
  }
  .ant-modal-content{
    border-radius: 12px !important;
  }
  .ant-modal-title{
    font-size: 24px;
    font-weight: bold;
    font-family: "Sk-Modernist-Bold";
    text-align: center;
    color: #626161;
    margin-top: 7px;
    padding-right: 20px;
  }
  .ant-select-selector {
    border: none !important;
    width: -webkit-fill-available;
  }
  .ant-picker{
    padding: 10px;
    margin-top: 5px;}
  
  .ant-modal-body {
      padding: 30px;
    padding-top:0;
    }
  
  .ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
      background: transparent;
    }
  .ant-picker-input > input{
      font-size: 18px;
      line-height: 25px;
      color: #626161;
      font-family: "Sk-Modernist-Bold";
    } 
}
 
.ant-modal-close-x{color: #000000;}

.order-modal-text{
  font-weight: bold;
  color: #7b7b7b;
  font-size: 14px;
  font-family: "Sk-Modernist-Bold";
}
 
.ant-select-selection-placeholder
  {font-style: normal;
    font-size: 18px;
    line-height: 25px;
    color: #7B7B7B;
    font-family: "Sk-Modernist-Bold";}

.ant-picker-input input::placeholder{
  font-style: normal;
  font-size: 18px;
  line-height: 25px;
  color: #7B7B7B;
  font-family: "Sk-Modernist-Bold";
  }

.ant-input-prefix {
 margin-right: 10px;
}

.mb-30 {
  margin-bottom: 30px;
}

.select-style {
  flex-direction: column;
  width: 100%;
  padding: 8px 10px;
  border: none;
  background-color: transparent;
  min-height: 50px;
  color: #000000;
  font-family: "Sk-Modernist-Regular";
  font-size: 17px;
  line-height: 20px;
  border: 1px solid #929A9A !important;
  box-sizing: border-box;
  border-radius: 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(images/select.png);
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 22px;
  background-size: 14px;
}

select:focus {
  outline: none;
}

.ant-btn-primary {
  color: #fff;
  background: #2C384C;
  border-color: #2C384C;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background: #2C384C;
  border-color: #2C384C;
}

.radius12 {
  border-radius: 12px;
}

.font_size_family {
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    font-family: "Sk-Modernist-Bold";
    text-align: center;
}

.btn_pad {
  padding: 12px 40px;
  height: auto;
  min-width: 150px;
}

.btn_pad1 {
	padding: 10px 40px!important;
	height: auto!important;
	min-width: 155px!important;
}

a:hover {
  color: #265D6C;
}

.ant-layout{
  background: #ffffff;
  min-height: 100%;}

.ant-layout-header {
    background-color: #fff!important;
    padding: 0px !important;
    height: fit-content !important;
}

.ant-layout-footer {
	padding: 0 !important;
}

.ant-layout-sider {
	background: #fff !important;
	min-width: 350px !important;
}

.ant-layout.ant-layout-has-sider > .ant-layout-content {
	overflow-x: hidden;
	padding: 0 50px;
}

.our_order{font-style: normal;font-weight: bold;font-size: 38px;line-height: 48px;color: #2C384C;}

.customized_banner_new{
	width:100%;
	height: 616px;
	position: relative;
	background-size: cover;
	background-position: center;
}

.search-card-subtext {
	padding-left: 0px;
	margin-bottom: 0;
	padding-bottom: 0;
	font-size: 16px;
	line-height: 36px;
	color: #929A9A;
	font-family: "Sk-Modernist-Bold";
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
	text-align: left;
}

.pickup-location-subtext{
  padding-left: 0px;
  margin-bottom: 0;
  padding-bottom: 0;
  font-size: 16px;
  line-height: 36px;
  font-family: "Sk-Modernist-Bold";
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  text-align: center;
}

.content-heading-col {
	display: flex;
	text-align: center;
	flex-direction: column;
	align-content: flex-start;
	justify-content: start;
	padding: 0px;
}

.content-heading {
	font-family: "Sk-Modernist-Bold";
	margin-bottom: 0.2em !important;
	font-size: 40px;
    line-height: 48px;
	color: #2C384C;
	padding-bottom: 30px;
}
.content-heading-distance {
	color: #626161;
	padding-left: 0px;
}
.content-sub-heading {
	font-family: "Sk-Modernist-Bold";
    text-align: left;
    font-size: 20px;
    line-height: 24px;
	color: #929A9A;
	margin-bottom: 0;    
}

.full_content{ padding: 0 50px;}

.order_title{font-style: normal;font-weight: bold;font-size: 32px;line-height: 42px;color: #2C384C;font-family: "Sk-Modernist-Bold";}
.order_description{font-style: normal;font-weight: bold;font-size: 14px;line-height: 20px; padding-top:10px;color: #929A9A;font-family: "Sk-Modernist-Bold";}   
.order_date{padding-top: 10px;font-style: normal;font-weight: bold;font-size: 15px;line-height: 20px;text-align: right;color: #265D6C;font-family: "Sk-Modernist-Bold";}
.order_price{font-style: normal;font-weight: bold;font-size: 28px;line-height: 32px;color: #2C384C;font-family: "Sk-Modernist-Bold";}
.order_price_mobile{display: none;} 
.price_button { margin-top: 5px;}
.order_list{margin-top: 50px;}
.myorder_list{margin-bottom: 80px;}
.tab_heading{font-style: normal;font-weight: bold;font-size: 32px;line-height: 42px;color: #2C384C;}
.detail_info{padding: 10px 40px;width: auto;}
.restaurant_items{
  margin-top: 20px;
  .ant-collapse-header {font-style: normal;font-weight: bold;font-size: 24px;line-height: 35px;color: #2C384C;padding-right: 0px !important;}
}
.show_mobile{display:none;}
.repeat_your_order {
  border-radius: 12px;
  min-width: 243px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  padding: 12px 50px;
  height: auto;
  text-align: center;
  float: right;
}
.our_order_div, .our_order_list
{
  margin-top: 20px;
  .repeat_your_order {
  border-radius: 12px;
  min-width: 243px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  padding: 12px 50px;
  height: auto;
  margin: 50px 0 auto 0;
  text-align: center;
  float: right;
}
}


.ant-select-dropdown{
  background: #FFFFFF;
box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
border-radius: 12px;
padding: 30px 10px;
width: 340px!important;
min-width: 340px!important;
float: right;
left: calc(50% - 75px) !important;
}
.ant-picker-panel-container{background: #FFFFFF;
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 12px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
  margin-top: 10px;
}
.Ingridients_div{width:100%;background-color: #ffffff; border: 1px solid rgba(0, 0,0, 0.2);box-sizing: border-box;border-radius: 12px;
  display: flex;align-items: center;justify-content: center; height: fit-content;}   
.sider-div {    
  padding-top: 60px;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    font-style: normal;
    font-family: "Sk-Modernist-Bold";
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #2C384C;
    padding: 12px 14px;
    padding-left: 0px;
    padding-right: 40px;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
    padding-right: 16px;
  }
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }
  .ant-checkbox-wrapper {
    font-style: normal;
    font-size: 15px;
    line-height: 35px;
    color: #929A9A;
    font-family: "Sk-Modernist-Bold";
  }
  .ant-checkbox-inner {
    border: 2px solid #929A9A;
    border-radius: 4px;
  }
  .ant-checkbox + span {
    padding-right: 8px;
    padding-left: 15px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #2C384C;
    border-color: #2C384C;
  }
  .ant-checkbox-checked::after {
    border: 1px solid #2C384C;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,.ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #2C384C;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    font-size: 15px;
    margin-top: -2px;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg{
    color: #626161;
    margin-top: 0px;
  }
}

.time-picker-select {
  .rc-virtual-list .rc-virtual-list-holder .rc-virtual-list-holder-inner .ant-select-item
  {padding: 10px;
    margin: 5px 5px;
    width: 30%;
    float: left;}
  .rc-virtual-list .rc-virtual-list-holder .rc-virtual-list-holder-inner{
      display: block!important;
    }     
}
.ingridients_weight{width:100%;font-style: normal;font-weight: bold;font-size: 15px;line-height: 20px;color: #929A9A;}
.ingridients_name{width:100%;font-style: normal;font-weight: bold;font-size: 15px;line-height: 19px;color: #2B374A;}
.Ingridients_label{margin-top:0px;font-style: normal;font-weight: bold;font-size: 25px;line-height: 32px;color: #2C384C;}
.ing_image{
  position: absolute;
  width: 100%;
  height: 100%;
  margin: auto;
}
.ing_img_div{
  position: relative;
  height: 50px;
  width: 50px; 
  object-fit: cover;
  display: block;
}
.ingredient_image_div{
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.header-div {
	padding: 0 50px;
	line-height: 64px;
	background: #fff;
}
div{
  .home_page_logo {
    margin: 3px 0 3px 0;
    .ant-skeleton-avatar-lg{
      min-width: 100px;
    }
    img {
      width: auto;
      height: 55px;
    }
  }
}

.site-layout-content {
  background: #fff;
  padding: 40px 0 80px 0;
  min-height: 320px;
}
.input_label{font-style: normal;font-weight: bold;font-size: 16px;line-height: 20px;color: #7B7B7B;}
.myorder_pic{width:100%;border-radius: 8px;}
button.load-more-btn {
  border-radius: 12px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  height: auto;
  text-align: center;
  margin: 0;
  padding: 12px 35px;
 }
 .rowborder{
  border-bottom: 1px solid #C4C4C4;
  padding: 0px 0;}

.pickup_icon{
  padding: 10px 0;
  svg{ 
    width:20px; 
    color: #000000;} 
  }

.ant-select-item-option-selected:not(.ant-select-item-option-disabled)
    {background: #3D8C8D;
      color: #FFFFFF;}  

.noborder-full-width{
        width: -webkit-fill-available;
        border: none !important;
        color: #7b7b7b !important;
        font-weight: bold !important;
      }        

.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector,.ant-picker-focused
{box-shadow: none;} 

.rc-virtual-list-holder{
  padding: 0px;
}
.rc-virtual-list-holder::-webkit-scrollbar {
  width: 3px;
  border-radius: 30px;
}
.rc-virtual-list-holder ::-webkit-scrollbar-track {
  background: #ffffff;
  box-shadow: none;
}
.rc-virtual-list-holder::-webkit-scrollbar-thumb {
  background: #2B374A;
}

.ant-picker-header-super-prev-btn,
  .ant-picker-header-super-next-btn
  {display:none;}

.ant-picker-header-view{font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #265D6C;
    font-family: "Sk-Modernist-Regular";
    font-weight: 600;}
.ant-picker-cell {   
      color: #C4C4C4;}
  
.ant-picker-cell-in-view, .ant-picker-header button {
        color: rgba(0, 0, 0, 1);
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: #265D6C;
}
.ant-select-selection-item{font-size: 17px;
  line-height: 25px;
  color: #626161;
  font-family: "Sk-Modernist-Bold";}
  
.mr-top{
    margin-top: 30px; 
}
.simple_select {
  flex-direction: column;
  width: 100%;
  padding: 13px 10px;
  border: none;
  background-color: transparent;
  min-height: 50px;
  color: #000000;
  font-family: "Sk-Modernist-Regular";
  font-size: 17px;
  line-height: 20px;
  border: 1px solid #929A9A;
  box-sizing: border-box;
  border-radius: 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(./images/select.png);
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 22px;
  background-size: 14px;
}

.repeat-info{
  font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
    color: #2C384C;
    padding-top: 50px;
    padding-bottom: 25px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
}

.heading {
  font-style: normal;
  font-size: 20px;
  line-height: 25px;
  font-family: "Sk-Modernist-Bold";
  color: #2C384C;
}

.ant-collapse {
  border: none !important;
  background-color: #fff !important;
  .ant-collapse-item {
    border-bottom: none !important;
  }
  .ant-collapse-content {
    border-top: none !important;
  }
  .ant-collapse-content-box {
    flex-direction: column !important;
    display: flex !important;
  }
  
  .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow{font-size: 18px;color: #626161;margin-top: 5px;margin-right: 10px;}
  
 .ant-collapse-item-disabled > .ant-collapse-header, .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
    color: rgba(0, 0, 0, 0.85);
    cursor: not-allowed;
  }
}

.panel-div {
  font-size: 14px;
  font-weight: bold;
  font-family: "Sk-Modernist";
}

.anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  margin-right: 5px;
}
.ant-checkbox-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  color: #929A9A;
}

.ant-checkbox {
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  padding-top: 0px;
}

.top_row{border-bottom: 1px solid rgba(146, 154, 154, 0.32);padding-bottom: 30px;}
.back_search{
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: #2C384C;
  margin-bottom: 5px;
  svg{width: 18px;margin-right: 5px;}
}
.Sub_Heading{font-style: normal;font-weight: bold;font-size: 24px;line-height: 35px;color: #2C384C;}
.pickup_label, .location_label{font-style: normal;font-weight: bold;display: block;font-size: 16px;line-height: 28px;color: #929A9A;}
.pickup_time, .location_restraurant{font-style: normal;margin-bottom: 10px;font-weight: bold;display: block;font-size: 14px;line-height: 20px;color: #2C384C;}

.ordered_total{font-style: normal;font-weight: bold;font-size: 22px;line-height: 28px;color: #2C384C;}
div.ordered_total > strong {
  font-size: 26px;;
}
.mt-40{margin-top: 40px;}
.ordered_items {padding: 30px 0px;border-bottom: 1px solid #C4C4C4;}
.ordered_item{font-style: normal;font-weight: bold;font-size: 18px;line-height: 23px;color: #2C384C;padding-bottom: 10px;}
.ordered_qua{
  font-style: normal;
  font-weight: bold; 
  font-size: 15px;
  line-height: 19px;
  color: #929A9A;
  span {color: #000; display: inline-block; padding-left: 3px;}
}
.ordered_price{font-style: normal;font-weight: bold;font-size: 17px;line-height: 22px;text-align: right;color: #2C384C;}
.ordered_cost{font-style: normal;font-weight: bold;font-size: 20px;line-height: 25px;color: #2C384C; text-align: right;}
.radius8 {
	border-radius: 8px!important;
}
.content-heading-div {
	display: flex;
	flex-direction: row;	
}

.filters_mobile_btn {
	background: #E9E9E9!important;
	border-radius: 12px;
	border: none;
	width: auto;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 22px;
	color: #2C384C!important;
	padding: 10px 40px;
	height: auto;
	min-width: 150px;
	font-family: "Sk-Modernist-Bold";
	text-align: center;
	text-shadow: none;
	box-shadow: none;
  margin: 12px 0;
  svg {
    color: #000000;
    margin-right: 8px;
  }
}

.footer_link{
  list-style-type: none;
  margin: 0; 
  padding: 0; 
  text-align: center;
  li{display:inline-block; padding: 0 8px; margin: 0px 0;} 
  li a.bottom-links-text {
    font-family: "Sk-Modernist-Bold";
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      color: #fff;
  }
  li a.bottom-links-text:hover {	
    color: #B7D568;
}
} 
.order_information{
  padding: 25px 0px;
  .ordered_item{padding-bottom: 25px;}
  .ordered_qua{padding-left: 10px;}
}

.weekday_div{
  background-color: #2C384C;
  max-width: 371px;
  height: auto;
  border-radius: 8px;
  margin: 30px 0px;
  color: #ffffff;
  text-align: center;
  padding: 15px 0px;
  font-weight: 700;
  font-size: 18px;
}

.ant-picker-week-panel-row-selected:hover{
  background: #265D6C !important;
  td{
    background: none;
  }
  :hover{
    background: #265D6C !important;
    td{
      background: none;
    }
  }
}


.ant-picker-week-panel-row-selected{
  background: #265D6C !important;
  td{
    background: none;
  }
  :hover{
    background: #265D6C !important;
    td{
      background: none;
    }
  }
}

@media only screen and (max-width: 480px) {
  div{
    .home_page_logo {
      width: 125px;
      height: 63px;
      img {
        width: auto;
        height: 40px;
      }
    }
  }
}

@media only screen and (max-width: 767px){
  .ant-input-affix-wrapper{padding: 10px 10px;}
  .select-style{padding: 6px 10px;min-height: 44px;background-position-y: 18px;}
  .ant-layout-sider{display:none;}
  .ant-layout.ant-layout-has-sider > .ant-layout-content{padding: 0 20px;}
.our_order {font-size: 28px;line-height: 38px;}
.header-div {padding: 0 20px; position: fixed;z-index: 9; top:0; left: 0; right: 0;border-bottom: 1px solid rgba(196, 196, 196,0.5);}
.customized_banner_new{
	width:100%;
	height: auto;
    position: relative;
    margin-top: 70px;
}

  .search-card-subtext{font-size: 14px;line-height: 16px;}  
.pickup-location-subtext{font-size: 14px;line-height: 16px;text-align: center;}
.content-heading-col {text-align: left;}
.content-heading { font-size: 32px;line-height: 38px;text-indent: 0;padding-bottom: 5px;}
.content-heading-distance{display: block;}
.content-sub-heading{display:block;font-size: 16px;    line-height: 19px;}
.full_content {  padding: 70px 20px 20px 20px;}
.order_title{ margin-top: 20px;font-size: 24px;line-height: 34px;}
.order_date{text-align: left; font-size: 14px; display: inline-block;}
.order_price_mobile {display: inline-block;text-align: right;float: right;font-size: 24px; line-height: 28px;font-style: normal;font-weight: bold;color: #2C384C;font-family: "Sk-Modernist-Bold";}
.order_price{display:none;}
.price_button { margin-top: 20px;}
.myorder_list{margin-bottom: 50px;}
.tab_heading{padding-top: 5px;}
.detail_info {padding: 13px 40px;width: 100%;margin-bottom: -5px;}
.filters_mobile_btn{width: 100%;}
.filters_mobile_btn.detail_info {
  margin-bottom: 12px;
}
.restaurant_items{
  .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header{padding: 12px 0px;}
  .ant-collapse-content > .ant-collapse-content-box{padding:0;}
}

.show_desktop{display:none;}
.show_mobile{display:block;}
.repeat_your_order{padding: 13px 39px;height: auto;width: 100%;}
.our_order_div{margin-top: 0px; h1{margin-bottom: 0px;}}
.our_order_list{margin-top: 0px;}
.mb-10 {margin-bottom: 10px!important;}
.ant-select-dropdown{
  float: right;
  left: 0!important;
  right: 0!important;
  margin: auto;box-shadow: none;
  border-radius: 0px;
  width: 100%!important;
  min-width: 100%!important;
}
.ant-picker-panel-container{border: 0px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 0px;
  box-shadow: none;}
.ant-picker-dropdown,.ant-picker-panel-container .ant-picker-panel, .ant-picker-date-panel .ant-picker-content, .ant-picker-decade-panel, .ant-picker-year-panel, .ant-picker-quarter-panel, .ant-picker-month-panel, .ant-picker-week-panel, .ant-picker-date-panel, .ant-picker-time-panel
    {width:100%;}
  .sider-div {  
    text-align: left;
    padding-left: 5px !important;
    padding-right: 5px !important;
  } 
    .time-picker-select {
      .rc-virtual-list .rc-virtual-list-holder .rc-virtual-list-holder-inner .ant-select-item
    {
    margin: 2px;
     width: 30%;
     float: left;
    font-size: 3.5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    
    }
    .ant-select-item-option-content {
      overflow: visible;
       }
    }
    .weekday_div{
      background-color: #E9E9E9;
      width: 100%;
      color: #2C384C;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px){ 
   .pickup-location-subtext{font-size: 14px;line-height: 28px;text-align: center;}
   .content-heading{text-indent: 0px;font-size: 32px; text-align: left; line-height: 42px;}
   .content-heading-distance{display: block;}
   .order_title{font-size: 24px;  line-height: 34px;}
   .order_date{font-size: 14px;}
   .order_price_mobile, .order_price{font-size: 24px; line-height: 28px;}
}

@media only screen and (min-width: 992px) and (max-width: 1199px){ 
  .search-card-subtext{font-size: 14px;line-height: 28px;}
  .pickup-location-subtext{font-size: 14px;line-height: 28px;text-align: center;}
}

@media only screen and (min-width: 1200px) and (max-width: 1599px){
  .search-card-subtext{font-size: 15px;line-height: 30px;}
  .pickup-location-subtext{font-size: 15px;line-height: 30px; text-align: center;}
.content-heading {font-size: 35px;line-height: 45px;}
.content-sub-heading {font-size: 15px; line-height: 22px;}

}