.profile_tab .ant-tabs-tab{
    font-style: normal;
    font-size: 18px;
    line-height: 25px;
    color: #828282;
    font-family: "Sk-Modernist-Bold";
    margin: 0 25px 0 0;
}
 .ant-tabs-top.profile_tab > .ant-tabs-nav::before{border-bottom: 0px solid #f0f0f0;}
.ant-tabs-top.profile_tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {color: #2C384C;font-weight: 600;}
.ant-tabs-top.profile_tab .ant-tabs-ink-bar{display: none;}
.profile_tab .ant-tabs-content-holder{padding: 0px 0;}
